<template>
  <div class="panel-editor-toolbar" v-if="isCurrentPanel">
    <portal-target name="panel-editor-toolbar" class="custom-panel-toolbar" />
    <template v-for="(item, ix) in panelToolbar">
      <span
        class="btn btn-xs"
        :key="ix"
        :title="$t(item.hint)"
        @click.stop.prevent="
          $emit('click', $event, {...item, panelName: panelName})
        "
      >
        <i :class="item.icon"></i>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "PanelEditorToolbar",
  props: {
    panelName: {
      type: String,
      required: true,
      default: ""
    }
  },
  computed: {
    currentDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    clipboard: {
      set(value) {
        return this.$store.dispatch("dashboard/setClipboard", value);
      },
      get() {
        return this.$store.getters["dashboard/clipboard"] || null;
      }
    },
    isCurrentPanel() {
      return this.currentDraftPanel
        ? this.currentDraftPanel.name == this.panelName
        : false;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    nextConfigurationForm() {
      const panels =
        (this.$store.getters["dashboard/draft"]?.template ?? undefined)
          ?.panels ?? [];
      if ("MainForm" == this?.sidebar?.name) {
        if (this?.sidebar?.contentPanelWidget) {
          return [
            {
              icon: "fa fa-sliders",
              title: "panel_content_properties",
              handler: "editSpecificPanelProperties"
            }
          ].concat(
            panels.length > 1
              ? [
                  {
                    icon: "fa fa-expand",
                    title: "expand",
                    handler: "expandPanel",
                    hint: "synoptic.events.actions.panel_expand"
                  }
                ]
              : []
          );
        } else {
          return [];
        }
      } else {
        return [
          {
            icon: "fa fa-gear",
            title: "panel_properties",
            handler: "editGenericPanelProperties"
          }
        ].concat(
          panels.length > 1
            ? [
                {
                  icon: "fa fa-expand",
                  title: "expand",
                  handler: "expandPanel",
                  hint: "synoptic.events.actions.panel_expand"
                }
              ]
            : []
        );
      }
    },
    panelToolbar() {
      return this.nextConfigurationForm.concat([
        {
          icon: "fa fa-trash",
          title: "remove",
          handler: "removePanel",
          hint: "hints.remove_panel"
        },
        {
          icon: "fa fa-close",
          title: "close",
          handler: "closePanel",
          hint: "close"
        }
      ]);
    }
  }
};
</script>

<style scoped>
.panel-editor-toolbar {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 22px;
  z-index: 100;
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255, 0.1) 10%,
    rgb(255, 255, 255, 0.6) 30%,
    rgb(255, 255, 255, 0.85) 80%,
    rgb(255, 255, 255, 1)
  );
  border-radius: 3px;
  overflow: hidden;
  padding-left: 5px;
}

.skin-dark .panel-editor-toolbar {
  background-image: linear-gradient(
    to right,
    rgb(30, 40, 44, 0.6) 30%,
    rgb(30, 40, 44, 0.85) 80%,
    rgb(30, 40, 44, 1)
  );
  color: var(--skin-dark-light);
}

.panel-editor-toolbar > .btn:hover {
  cursor: pointer;
  background-color: #e6e6e6;
  color: #337ab7;
}

.skin-dark .panel-editor-toolbar > .btn:hover {
  background-color: transparent;
  color: #fff;
}

.panel-editor-toolbar > .custom-panel-toolbar {
  display: inline-block;
  border-right: 1px solid lightgray;
  padding-right: 5px;
  margin-right: 5px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
