<template>
  <div 
    class="dashboard-inner"
    :class="{'skin-dark': isThemeSkinDark, 'ignore-theme': !isThemeSkinDark}"
  >
    <div class="row" v-if="!panels.length">
      <div class="col-md-6 col-md-offset-3">
        <div
          class="alert bg-warning fade show in"
          style="margin-top: 20px; box-shadow: 3px 3px 7px gray"
        >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-hidden="true"
            @click.stop.prevent="$router.go(-1)"
          >
            x
          </button>
          {{ $t("failed_to_load_panels") }}
        </div>
      </div>
    </div>
    <div class="row" v-for="(row, ixRow) in grid" v-bind:key="ixRow">
      <div
        class="col-xs-12"
        v-for="(col, ixCol) in row"
        :key="ixCol"
        :class="col.column_class"
      >
        <div v-for="(panel, ixPanel) in col.panels" v-bind:key="ixPanel">
          <EquipmentDashboardPanel
            :equipment="equipment"
            :display="display"
            :name="panel"
            :screenId="screenId"
            @mounted="panelMounted"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {isEqual} from "lodash";
import DashboardContentBase from "@/equipment-dashboard-templates/dashboard-content-base.vue";

export default {
  name: "DashboardContentViewer",
  extends: DashboardContentBase,
  props: {
    display: {
      type: Object,
      required: true
    }
  },
  computed: {
    expandedPanel() {
      return this.$store.getters["dashboard/expandedPanel"];
    },
    fullscreenPanel() {
      return this.$store.getters["dashboard/fullscreenPanel"];
    },
    screen() {
      return this.$store.getters["dashboard/screens"].find(
        ({id}) => parseInt(id) == parseInt(this.screenId)
      );
    },
    currentEquipment() {
      return {
        connectorId: (this.equipment && this.equipment.id) || null,
        device: this.$store.getters["deviceId"] || null
      };
    },
    isThemeSkinDark() {
      return this?.display?.css_theme == "skin-dark";
    }
  },
  watch: {
    mountedPanels: {
      handler(n, o) {
        if (!o && n && this.display.render_version) {
          this.$nextTick(() => {
            const check = () => {
              return new Promise((resolve, reject) => {
                let _c_ = 10;
                let _v_ = setInterval(
                  () => {
                    if (document.querySelector("div.outer-panel > section")) {
                      clearInterval(_v_);
                      _v_ = null;
                      resolve();
                    } else {
                      _c_--;
                      if (!_c_) {
                        clearInterval(_v_);
                        _v_ = null;
                        reject();
                      }
                    }
                  },
                  10,
                  this
                );
              });
            };
            check().then(() => {
              this.resize();
            });
          });
        }
      },
      deep: true
    },
    largePanel(n, o) {
      this._prvStyle = this._prvStyle || {};
      let panel = null;
      if (o && this._prvStyle[o]) {
        panel = this.connectorPanel(o);
        if (panel) {
          this.$set(panel, "style", this._prvStyle[o]);
        }
        delete this._prvStyle[o];
      }
      if (n) {
        panel = this.connectorPanel(n);
        if (panel) {
          let style = JSON.parse(JSON.stringify(panel?.style || {}));
          if (!this._prvStyle[n]) {
            this._prvStyle[n] = panel?.style;
          }
          let headerHeight = this?.display?.page_header?.show ? 65 : 15;
          if (headerHeight) {
            style["min-height"] = `calc(100dvh - ${headerHeight}px)`;
          }
          delete style["max-height"];
          this.$set(panel, "style", style);
        }
      }
    },
    currentEquipment(n, o) {
      if (n && o && !isEqual(n, o) && !this.busy) {
        this.refresh();
      }
    }
  },
  methods: {
    resize() {
      if (!this.display.render_version || !this.layout) return;
      this.layout.forEach((row, ir) => {
        let rowHeight = this.onResizeReferenceColumn(ir);
        if (row.length == 1 && row[0].panels.length == 1) {
          let panelName = row[0].panels[0];
          let panel = this.panel(panelName);
          if (panel && panel.options.fitToPage) {
            let panelName = row[0].panels[0];
            let panel = this.panel(panelName);
            if (panel && panel.options.fitToPage) {
              let el = document.getElementsByClassName(`__${panelName}__`);
              if (!el || !el.length) return;
              // const mobileHeader = false; // todo: impmenent
              // let offsetY = 0;
              // offsetY += this?.display?.page_header?.show ? 60 : 0;
              // offsetY += mobileHeader ? 50 : 0;
              let offsetY = this.$utils.isMobile()
                ? 0
                : el[0].getBoundingClientRect().y;
              el[0].style["min-height"] = "auto"; // TODO: min height can set on panel
              el[0].style["max-height"] = "100vh";
              el[0].style["overflow"] = `hidden`;
              el[0].style["height"] = `${window.innerHeight -
                (offsetY + window.scrollY)}px`;
              el[0].parentNode.style["padding-bottom"] = "0";
              return;
            }
          }
        }
        if (!rowHeight) return;
        if (row.length > 1) {
          for (var col = 1; col < row.length; col++) {
            this.onResizeColumn(ir, col, rowHeight);
          }
        }
      });
      return;
    },
    validateAndTriggerEvent(name) {
      if (!this.screen || !this.display) return;
      const actions = (this?.display?.on || {})[name]?.actions ?? [];
      if (!actions.length) return;
      (actions || []).forEach((action) => {
        this.$root.$emit("custom_action", {
          context: this,
          source: this.screen,
          event: name,
          connector: this.equipment || null,
          action: action
        });
      });
    }
  },
  mounted() {
    if (!this?.panels?.length) return;
    this.$root.$on("dashboard:refresh", this.refresh);
    if (this.display.render_version) {
      this.$root.$on("panel:resized", this.delayedResize);
      window.addEventListener("resize", this.delayedResize, false);
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.dirty = true;
      //   }, 3000, true);
      // })
    }
    this.validateAndTriggerEvent("activated");
  },
  beforeDestroy() {
    // IMPORTANT!
    // remove any panel specific registred vuex module, in order to restore vuex getters reactivity
    (this.display.panels || []).forEach(({name}) => {
      if (name !== "synoptic" && this.$store.hasModule(name)) {
        this.$store.unregisterModule(name);
      }
    });
    this.$root.$off("dashboard:refresh", this.refresh);
    this.$root.$off("panel:resized", this.delayedResize);
    window.removeEventListener("resize", this.delayedResize, false);
    this.validateAndTriggerEvent("deactivated");
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>

<style>
body:has(.skin-dark):not(:has(.ignore-theme)) .dashboard-content {
  background: var(--skin-dark-allblack) !important;
}
</style>
