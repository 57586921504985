<template>
  <div class="me" :style="style" ref="renderedDashboard">
    <PageSettingsToolbar class="pdf-tool-bar" v-model="userSettings" />
    <template v-if="!updating">
      <div v-for="(doc, ixDoc) in docs" :key="ixDoc" class="paper">
        <div v-for="(row, ixRow) in doc" :key="ixRow">
          <table>
            <tbody>
              <tr>
                <td
                  v-for="(col, ixCol) in row"
                  :key="ixCol"
                  :style="{width: col.width + '%'}"
                >
                  <EquipmentDashboardPanel
                    :equipment="equipment"
                    :display="display"
                    :name="panel"
                    :screenId="screenId"
                    v-for="(panel, ixPanel) in col.panels"
                    :key="ixPanel"
                    :ref="panel"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import {computed} from "vue";
import {jsPDF} from "jspdf";
import {PDFDocument} from "pdf-lib";
import GoogleFonts from "@/assets/jspdf-fonts/google-fonts.json";
import FontAwesome from "@/assets/jspdf-fonts/fontawesome.json";
import EquipmentDashboardPanel from "@/components/equipment-dashboard-panel.vue";
import PageSettingsToolbar, {
  pageFormats
} from "@/components/widgets/page-settings-toolbar.vue";

const portraitScreenWidth = 1024;

jsPDF.API.events.push([
  "addFonts",
  function() {
    this.addFileToVFS(
      "Source Sans Pro-normal.ttf",
      GoogleFonts["Source Sans Pro-normal"]
    );
    this.addFileToVFS(
      "Source Sans Pro-bold.ttf",
      GoogleFonts["Source Sans Pro-bold"]
    );
    this.addFileToVFS(
      "Source Sans Pro-italic.ttf",
      GoogleFonts["Source Sans Pro-italic"]
    );
    this.addFileToVFS(
      "Source Sans Pro-bolditalic.ttf",
      GoogleFonts["Source Sans Pro-bolditalic"]
    );
    this.addFileToVFS(
      "FontAwesome-normal.ttf",
      FontAwesome["FontAwesome-normal"]
    );

    this.addFont("Source Sans Pro-normal.ttf", "Source Sans Pro", "normal");
    this.addFont("Source Sans Pro-bold.ttf", "Source Sans Pro", "bold");
    this.addFont("Source Sans Pro-italic.ttf", "Source Sans Pro", "italic");
    this.addFont(
      "Source Sans Pro-bolditalic.ttf",
      "Source Sans Pro",
      "bolditalic"
    );
    this.addFont("FontAwesome-normal.ttf", "FontAwesome", "normal");
  }
]);
const synopticDisplayPath = "div.synoptic-wrapper";
// const synopticDisplayPath =
//   "section.__synoptic__ > div > div.box-body > div > div";

export default {
  name: "DashboardPrintout",
  components: {
    EquipmentDashboardPanel,
    PageSettingsToolbar
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    display: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: "equipment_dashboard"
    },
    screenId: {
      type: [String, Number],
      default: () => 0
    }
  },
  provide() {
    return {
      pageSettings: computed(() => this.pageSettings)
    };
  },
  data() {
    return {
      busy: false,
      updating: false,
      userSettings: {
        orientation: "p",
        format: "a4"
      },
      skipUpdate: false
    };
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    preview() {
      return this.$route.path.startsWith("/dashboard/screen/view");
    },
    expandedPanel() {
      return this.$store.getters["dashboard/expandedPanel"];
    },
    panels() {
      let lst = null;
      if (this.expandedPanel) {
        lst = [this.expandedPanel];
      } else {
        lst = this.display.panels
          .filter((panel) => {
            return this.can(panel);
          })
          .map((panel) => {
            return panel.name;
          });
      }
      return lst;
    },
    layout() {
      var self = this;
      var role = self.$store.getters["user/loggedUserRole"] || null;
      if (role && "layout" in role) {
        return role.layout;
      } else {
        if (self.display && "layout" in self.display) {
          return self.display.layout;
        }
      }
      return null;
    },
    grid() {
      let self = this;
      let grid = [];
      if (this.busy) return grid;
      let layout = self.layout;
      if (layout) {
        layout.forEach(function(items) {
          grid.push(self.buildClasses(self.row(items)));
        });
      }
      return grid.filter((row) => row.length > 0);
    },
    largePanel() {
      return (
        this.$store.getters["dashboard/expandedPanel"] ||
        this.$store.getters["dashboard/fullscreenPanel"] ||
        ""
      );
    },
    sidebar() {
      return this.$store.getters["dashboard/sidebar"] || null;
    },
    layoutConfig() {
      let cfg = this?.display?.layoutConfig || {
        rowHeight: [],
        pageBreak: [0]
      };
      return cfg;
    },
    docs() {
      var refs = JSON.parse(JSON.stringify(this.layoutConfig))?.pageBreak || [];
      refs = refs?.length ? refs : [0];
      let lst = [];
      this.grid.forEach((row, ix) => {
        if (ix >= refs[0]) {
          refs.splice(0, 1);
          lst.push([]);
        }
        if (lst.length) {
          lst[lst.length - 1].push(row);
        }
      });
      return lst;
    },
    printStatus: {
      set(value) {
        this.$store.dispatch("setPrint", value);
      },
      get() {
        return this.$store.getters.print;
      }
    },
    pageSettings() {
      const p = this.userSettings.format; // user selected paper
      const o = this.userSettings.orientation; // orientation;
      return {
        unit: "pt",
        padding: 10,
        orientation: o,
        format: p,
        height: pageFormats[p][1],
        width: pageFormats[p][0],
        scale: pageFormats[p][0] / portraitScreenWidth
      };
    },
    style() {
      let w = portraitScreenWidth;
      // whether landscape was selected, calculate its relative proportion
      if (this.pageSettings.orientation == "l") {
        w = w / (this.pageSettings.width / this.pageSettings.height);
      }
      return {
        "max-width": `${w}px`
      };
    }
  },
  watch: {
    largePanel(panelName) {
      if (panelName) {
        let panel = this.connectorPanel(panelName);
        if (!panel) return;
        let style = JSON.parse(JSON.stringify(panel?.style || {}));
        delete style["min-height"];
        delete style["max-height"];
        this.$set(panel, "style", style);
      }
    },
    expandedPanel(n) {
      if (!this.display.render_version) return;
      if (!n && this.layout) {
        //this.delayedResize();
      } else if (n) {
        window.scrollTo(0, 0);
      }
    },
    pageSettings(n) {
      if (this.skipUpdate) {
        this.skipUpdate = false;
        return;
      }
      this.updating = true;
      this.$nextTick(() => {
        this.updating = false;
      });
    }
  },
  methods: {
    can(panel) {
      if (panel) {
        if (panel.rule) {
          return this.$can("view", panel.rule);
        }
        return true;
      }
      return false;
    },
    row(items) {
      let lst = JSON.parse(JSON.stringify(items));
      lst.forEach((item) => {
        item.panels = item.panels.filter((name) => {
          return this.panels.indexOf(name) >= 0;
        });
      });
      return lst;
    },
    buildClasses(row) {
      let max_col = 12;
      if (this.mode == "editor" && !this.preview) {
        max_col = 11;
        if (this.sidebar && !this.sidebar.collapsed) {
          max_col -= 1;
        }
      }
      let columns = [];
      for (var icol in row) {
        let hasPanel = false;
        for (var ipanel in row[icol].panels) {
          var panelName = row[icol].panels[ipanel];
          if (panelName) {
            hasPanel = true;
          } else {
            if (icol > 0) {
              row[icol - 1].width += row[icol].width;
            }
          }
        }
        if (hasPanel) {
          columns.push(row[icol]);
        }
      }
      var sum_informed = columns
        .map(function(i) {
          return i.width || 0;
        })
        .reduce((a, b) => a + b, 0);
      var sum_pending = 100 - sum_informed;
      var empty_ones = columns.filter(function(i) {
        return !("width" in i) || i.width <= 0 || i.width >= 100;
      }).length;
      var portion = empty_ones ? sum_pending / empty_ones : 0;
      var sum_calc = 0;
      columns
        .map(function(i) {
          i.width_calc = i.width || portion;
          sum_calc += i.width_calc;
          return i;
        })
        .map(function(i) {
          i.width_class_value =
            sum_calc > 0
              ? Math.round((i.width_calc * max_col) / sum_calc)
              : max_col;
          i.column_class =
            "col-sm-" +
            (i.width_class_value > 0 ? i.width_class_value : max_col);
          return i;
        });
      return columns;
    },
    refresh() {
      this.busy = true;
      this.$nextTick(() => {
        this.busy = false;
      });
    },
    editorPanel(panelName) {
      if (this.mode != "editor") return null;
      // check current one
      return (
        this.$store.getters["dashboard/draftPanel"]({
          screenId: this.screenId,
          panelName: panelName
        }) || null
      );
    },
    connectorPanel(panelName) {
      return (this?.display?.panels || []).find((i) => i.name == panelName);
    },
    panel(panelName) {
      return this.mode == "editor"
        ? this.editorPanel(panelName) || this.connectorPanel(panelName)
        : this.connectorPanel(panelName);
    },
    setAnimation(restore) {
      let $elList = document.getElementsByClassName("fa-spin");
      let $el;
      for (var i = 0; i < $elList.length; i++) {
        $el = $elList[i];
        if (restore) {
          if ("HIDisplay" in $el.dataset) {
            $el.style.display = $el.dataset["HIDisplay"];
            delete $el.dataset["HIDisplay"];
          }
        } else {
          $el.dataset["HIDisplay"] = $el.style.display;
          $el.style.display = "none";
        }
      }
    },
    downloadPdf(data) {
      const downloadURL = (data, fileName) => {
        var a;
        a = document.createElement("a");
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = "display: none";
        a.click();
        a.remove();
        a = null;
      };

      const downloadBlob = (data, mimeType, fileName) => {
        let blob = new Blob([data], {
          type: mimeType
        });
        let url = window.URL.createObjectURL(blob);
        downloadURL(url, fileName);
        setTimeout(
          () => {
            blob = null;
            return window.URL.revokeObjectURL(url);
          },
          1000,
          this
        );
      };

      let screen = (this.$store.getters["dashboard/screens"] || []).find(
        (i) => i.id == this.screenId
      ) || {name: "report"};
      let now = new Date()
        .toISOString()
        .split(".")[0]
        .replace(/[T\-:]/g, "");
      let fn = `${screen.name}_${now}.pdf`;
      downloadBlob(data, "application/pdf", fn);
      data = null;
    },
    incPrintStatus(inc) {
      this.printStatus = {
        total: this.printStatus.total,
        count: (this.printStatus.count || 0) + (inc || 1)
      };
    },
    async mergeDocs(docList) {
      const mergedPdf = await PDFDocument.create();

      const loadDoc = (item) => {
        return new Promise((resolve) => {
          PDFDocument.load(item.d).then((pdf) => {
            this.incPrintStatus();
            resolve({
              i: item.i,
              w: item.w,
              h: item.h,
              d: pdf
            });
          });
          item.d = null;
        });
      };

      let docs = docList.map(({i}) => i);
      Promise.all(docList.map((item) => loadDoc(item))).then((lst) => {
        lst.sort((a, b) => a.i - b.i);
        lst.forEach((data) => {
          mergedPdf
            .copyPages(data.d, data.d.getPageIndices())
            .then((copiedPages) => {
              copiedPages.forEach((page) => {
                page.setWidth(data.w);
                page.setHeight(data.h);
                mergedPdf.addPage(page);
              });
              const pos = docs.indexOf(data.i);
              if (pos >= 0) {
                docs.splice(pos, 1);
              }
              if (!docs.length) {
                mergedPdf.save().then((pdfBytes) => {
                  this.downloadPdf(pdfBytes);
                });
              }
            });
        });
      });
    },
    async generatePDF() {
      // https://rawgit.com/MrRio/jsPDF/master/docs/GState.html
      if (!this.$refs.renderedDashboard) return;
      const $docs = document.getElementsByClassName("paper");
      if ($docs.length < 1) return;
      // every document output an user collection of layout rows (maz)
      const createPdfDoc = ($docs, tasks, callback) => {
        let $el = $docs.item(tasks.length);
        this.incPrintStatus(0.6);
        let doc = new jsPDF({
          orientation: this.pageSettings.orientation,
          unit: this.pageSettings.unit,
          format: this.pageSettings.format,
          hotfixes: ["px_scaling"]
        });
        doc.setFont("Source Sans Pro", "normal");
        doc.setFont("FontAwesome", "normal");
        doc.html($el, {
          html2canvas: {
            scale: this.pageSettings.scale,
            logging: false,
            allowTaint: false,
            imageTimeout: 20000,
            removeContainer: true,
            restoreContext: true,
            useCORS: true,
            onrendered: () => {
              this.incPrintStatus(0.4);
            }
          },
          callback: (doc) => {
            // for individual document test purposes
            // doc.save(`${ix}.pdf`);
            // console.log(`<${ix}`);
            tasks.push({
              i: tasks.length - 1,
              w: doc.getPageWidth(),
              h: doc.getPageHeight(),
              d: doc.output("arraybuffer")
            });
            doc = null;
            if (tasks.length == $docs.length) {
              tasks.sort((a, b) => a.i - b.i);
              callback();
            } else {
              setTimeout(
                () => {
                  createPdfDoc($docs, tasks, callback);
                },
                500,
                this
              );
            }
          },
          margin: [0, 2, 5, 2],
          x: 0,
          y: 0
        });
      };

      this.printStatus = {total: $docs.length, count: 0};
      window.scrollTo(0, 0);
      this.setAnimation(false);
      this.$nextTick(() => {
        this.$root.$emit("dashboard:printing", "pdf");
        this.$nextTick(() => {
          let lst = [];
          createPdfDoc($docs, lst, () => {
            this.mergeDocs(lst);
            this.$root.$emit("dashboard:printing", "idle");
            this.setAnimation(true);
          });
        });
      });
    },
    replaceSvgWithPng(svgElement) {
      return new Promise((resolve, reject) => {
        if (!svgElement || svgElement.tagName.toLowerCase() !== "svg") {
          resolve();
          return;
        }
        try {
          const xmlSerializer = new XMLSerializer();
          const svgStr = xmlSerializer.serializeToString(svgElement);
          const img = new Image();
          img.onerror = () => {
            reject();
          };
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = svgElement.clientWidth;
            canvas.height = svgElement.clientHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const pngUrl = canvas.toDataURL("image/png");
            const newImg = document.createElement("img");
            newImg.src = pngUrl;
            newImg.width = svgElement.clientWidth;
            newImg.height = svgElement.clientHeight;
            newImg.style.position = "absolute";
            newImg.style.left = `${svgElement.offsetLeft}px`;
            newImg.style.top = `${svgElement.offsetTop}px`;
            svgElement.replaceWith(newImg);
            resolve();
          };
          img.src = "data:image/svg+xml;base64," + window.btoa(svgStr);
        } catch (error) {
          reject();
        }
      });
    },
    svgToPNG(el) {
      return new Promise((resolve) => {
        const tasks = Array.from(el.querySelectorAll("svg")).map((svg) =>
          this.replaceSvgWithPng(svg).catch(() => null)
        );
        Promise.all(tasks).then((results) => {
          resolve(results.filter(Boolean));
        });
      });
    },
    async SVGImageToSVGElement(img) {
      if (!img || img.tagName !== "IMG") return;
      const imgUrl = img.src;
      if (!imgUrl.split("?")[0].endsWith(".svg")) return;
      try {
        const response = await fetch(imgUrl, {mode: "cors"});
        if (!response.ok) return;
        const svgText = await response.text();
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgEle = svgDoc.documentElement;
        if (svgEle.tagName.toLowerCase() == "svg") {
          svgEle.setAttribute("width", img.width);
          svgEle.setAttribute("height", img.height);
          svgEle.style.position = "absolute";
          svgEle.style.left = `${img.offsetLeft}px`;
          svgEle.style.top = `${img.offsetTop}px`;
          img.replaceWith(svgEle);
        }
      } catch (error) {
        console.log(error);
      }
    },
    SVGImagesToSVGElements(container) {
      return new Promise((resolve, reject) => {
        const images = Array.from(
          container.querySelectorAll("img")
        ).filter((img) => img.src.match(/\.svg(?:\?.*)?$/i));
        if (images.length === 0) {
          resolve();
          return;
        }
        Promise.all(images.map((img) => this.SVGImageToSVGElement(img)))
          .then(resolve)
          .catch(reject);
      });
    },
    renderSynopticImage(container) {
      // Due new browser restrictions, synoptic can not directly converted to image
      // This function does the following (Marco Zoqui)
      // 1) converts all synoptic SVG images to SVG elements (fetching the SVG content from server)
      // 2) renders synoptic container within a SVG element (.synoptic-printout)
      // 3) render the SVG elements as PNG image replacing them
      // 4) generates the PDF
      return new Promise((resolve) => {
        this.SVGImagesToSVGElements(container).then(() => {
          let el = document.createElement("svg");
          el.xmlns = "http://www.w3.org/2000/svg";
          el.width = container.clientWidth;
          el.height = container.clientHeight;
          let fo = document.createElement("foreignObject");
          fo.style.width = `${container.clientWidth}px`;
          fo.style.height = `${container.clientHeight}px`;
          fo.innerHTML = container.outerHTML;
          el.appendChild(fo);
          el.classList.add("synoptic-printout");
          el.style.display = "none";
          container.parentNode.appendChild(el);
          this.svgToPNG(container)
            .then(() => {
              resolve();
            })
            .catch(() => {
              resolve(); // something went wrong, but we can continue
            });
          return;
        });
      });
    },
    // renderSynopticImage(display) {
    //   return new Promise((resolve, reject) => {
    //     let r = display.getBoundingClientRect();
    //     const w = Math.ceil(r.width);
    //     const h = Math.ceil(r.height);
    //     try {
    //       const video = document.createElement("video");
    //       video.autoplay = false;
    //       video.controls = false;
    //       video.muted = true;
    //       navigator.mediaDevices
    //         .getDisplayMedia({
    //           video: {
    //             mediaSource: "screen",
    //             displaySurface: "browser",
    //             logicalSurface: false,
    //             selfBrowserSurface: "include",
    //             surfaceSwitching: "exclude"
    //           },
    //           audio: false,
    //           preferCurrentTab: true,
    //           systemAudio: "exclude",
    //           cursor: false
    //         })
    //         .then((stream) => {
    //           const canvas = document.createElement("canvas");
    //           canvas.width = w;
    //           canvas.height = h;
    //           const context = canvas.getContext("2d");
    //           context.webkitImageSmoothingEnabled = false;
    //           context.mozImageSmoothingEnabled = false;
    //           context.imageSmoothingEnabled = false;
    //           video.addEventListener("loadedmetadata", () => {
    //             video.play();
    //             context.drawImage(video, r.x, r.y, w, h, 0, 0, w, h);
    //             stream.getTracks().forEach((track) => track.stop());
    //             let img = new Image();
    //             // img.height = h;
    //             img.id = "SynopticScreenShot";
    //             img.src = canvas.toDataURL("image/jpeg", 1);
    //             display.parentNode.appendChild(img);
    //             display.style.display = "none";
    //             resolve();
    //           });
    //           video.srcObject = stream;
    //         });
    //     } catch (err) {
    //       console.error("Error: " + err);
    //       reject(err);
    //     }
    //   });
    // },
    print() {
      if (!document.querySelector("synoptic-printout")) {
        // If there is a Synoptic panel it will need an extra step
        let display = document.querySelector(synopticDisplayPath);
        if (display) {
          this.renderSynopticImage(display).then(() => {
            this.$nextTick(() => {
              this.generatePDF();
            });
          });
          return;
        }
      }
      this.generatePDF();
    }
  },
  created() {
    this.printStatus = {total: 0, count: 0};
    this.$root.$once("dashboard:pageSettings", (e) => {
      if (e) {
        this.$set(this, "userSettings", JSON.parse(JSON.stringify(e)));
        this.skipUpdate = true;
      }
    });
  },
  mounted() {
    this.$root.$on("dashboard:print", this.print);
    this.$root.$on("dashboard:refresh", this.refresh);
  },
  beforeDestroy() {
    this.printStatus = null;
    this.$root.$off("dashboard:print", this.print);
    this.$root.$off("dashboard:refresh", this.refresh);
  }
};
</script>

<style scoped>
.pdf-tool-bar {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.me {
  min-width: 1024px;
  min-height: 100vh;
  padding: 5px;
  box-shadow: 0px 2px 3px grey;
  margin: auto;
  background-color: white;
  position: relative;
}

.paper {
  margin: 0;
  padding: 5px;
  width: 100%;
  position: relative;
}

.paper > div {
  margin: 5px 0;
  /* width: 100%; */
  /* position: relative; */
}

.paper > div > table {
  width: 100%;
  border: 0;
}

.paper > div > table > tbody > tr > td {
  vertical-align: top;
}

.paper > section.page-break-line {
  border-top: 1px dashed #c9b7b7;
  margin: 10px 0;
  position: relative;
  width: 100%;
}

.paper > section.page-break-line > i {
  top: -8px;
  position: absolute;
  color: #c9b7b7;
}
</style>
