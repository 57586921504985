<template>
  <div
    class="pending-actions"
    v-if="actionsDisabled"
    :style="{
      '--spin-font-size': modal ? '80%' : '150%',
      '--spin-font-weight': modal ? 'normal' : '300'
    }"
  >
    <div>
      <PendingCommandIndicator
        class="tooltip-container"
        :entry="dataPendingSet[0]"
        :modal="modal"
        :draggable="draggable"
        @mouseInOut="mouseInOut"
      />
    </div>
  </div>
</template>

<script>
import PendingCommandIndicator from "@/components/data/pending-command-indicator.vue";
export default {
  name: "PendingActionsIndicator",
  components: {
    PendingCommandIndicator
  },
  props: {
    controlEvents: {
      type: Object,
      required: true,
      default: null
    },
    modal: {
      type: Boolean,
      default: false,
      required: false
    },
    draggable: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    dataIdPendingList() {
      return (
        this.$store.getters["dashboard/pendingCommandDataIdList"] || []
      ).map((id) => parseInt(id));
    },
    actionsPendingConfiguration() {
      let entry = null;
      if (this?.controlEvents) {
        Object.values(this?.controlEvents || {}).forEach((e) => {
          (e?.actions || []).forEach(({options}) => {
            if (options?.data_id?.value && options?.show_status?.value) {
              entry = entry || {};
              entry[options?.data_id?.value] = false;
            }
          });
        });
      }
      return entry;
    },
    dataPendingSet() {
      if (!this.actionsPendingConfiguration || !this.dataIdPendingList.length)
        return null;
      let lst = null;
      for (var dataId in this.actionsPendingConfiguration) {
        if (this.dataIdPendingList.indexOf(parseInt(dataId)) >= 0) {
          lst = lst || [];
          lst.push(
            (this.$store.getters["dashboard/dataList"] || []).find(
              ({id}) => parseInt(id) == parseInt(dataId)
            )
          );
        }
      }
      return (lst || []).filter((i) => i !== undefined);
    },
    actionsDisabled() {
      return this?.dataPendingSet?.length ? true : false;
    }
  },
  methods: {
    mouseInOut(option) {
      console.log(option);
      if (option) {
        this.$parent.$el.style.zIndex = 3;
      } else {
        this.$parent.$el.style.zIndex = this.parentZIndex;
      }
    }
  },
  mounted() {
    this.parentZIndex = getComputedStyle(this.$parent.$el).zIndex;
  }
};
</script>

<style scoped>
/* begin pending actions - overlay */
.pending-actions {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pending-actions > div {
  position: relative;
  background-color: rgba(255, 255, 255, 0.414);
  display: flex;
  justify-content: center;
  height: 100%;
  /* z-index: 1; */
}

.pending-actions > div > .tooltip-container {
  align-self: center;
  z-index: inherit;
}

.pending-actions > div > .tooltip-container::v-deep > div {
  position: relative;
}

.pending-actions > div > .tooltip-container::v-deep i.fa-spin {
  /* font-size: 150%; */
  font-size: var(--spin-font-size);
  font-weight: var(--spin-font-weight);
  color: #666;
  /* z-index: 0; */
}

/* end pending actions - overlay */
</style>
