<template>
  <section class="">
    <div v-if="isReady">
      <div v-if="hasPermission">
        <component
          v-if="display"
          :is="WIDGET"
          :equipment="equipment"
          :display="display"
          :screenId="selectedScreenId"
          :panelName="panelName"
        />
      </div>
      <InfoBox v-else preset="unauthorized" />
    </div>
    <div class="row" v-else>
      <div class="col-md-6 col-md-offset-3">
        <DashboardNotFound v-if="dashboardError" />
        <div v-else class="overlay overlay-loading">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
    <CustomActionManager
      v-if="!hasEventManager"
      v-bind:connector="equipment"
      @navigate:end="navigateEnd"
    />
  </section>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import DashboardEquipmentBase from "@/views/private/DashboardEquipmentBase.vue";
import ComponentNotAvailable from "@/components/component-not-available.vue";
import EquipmentDashboardCustom from "@/equipment-dashboard-templates/equipment-dashboard-custom.vue";
import DashboardPrintout from "@/equipment-dashboard-templates/dashboard-printout.vue";
import DashboardContentEditor from "@/equipment-dashboard-templates/dashboard-content-editor.vue";
import DashboardContentViewer from "@/equipment-dashboard-templates/dashboard-content-viewer.vue";

import DashboardNotFound from "@/components/dashboard-not-found.vue";
import CustomActionManager from "@/components/custom-action-manager.vue";
import InfoBox from "@/components/info-box.vue";

export default {
  name: "DashboardEquipment",
  extends: DashboardEquipmentBase,
  components: {
    PageHeader,
    ComponentNotAvailable,
    EquipmentDashboardCustom,
    DashboardContentEditor,
    DashboardContentViewer,
    DashboardPrintout,
    InfoBox,
    DashboardNotFound,
    CustomActionManager
  },
  computed: {
    userEquipmentDashboardTemplate() {
      let tplName =
        this.$root.config.equipment_dashboard_template ||
        "EquipmentDashboardCustom";
      return tplName;
    },
    WIDGET() {
      if (this.mode == "editor") {
        return this.$options.components.DashboardContentEditor;
      } else if (this.media == "print") {
        return this.$options.components.DashboardPrintout;
      } else {
        // var components = this.$options.components || {};
        // var tpl =
        //   this.userEquipmentDashboardTemplate || "ComponentNotAvailable";
        // return (tpl in components && components[tpl]) || ComponentNotAvailable;
        return this.$options.components.DashboardContentViewer;
      }
    },
    hasPermission() {
      if (!this.display || !this.templateReady || !this.isReady) return true;
      if (!this.$can("manage", "EquipamentoAcesso")) return false;
      if (this.$route.path.startsWith("/dashboard/screen")) return true;
      return this.$store.getters["user/hasUserAccessTo"](
        (this?.screen || {})?.process_area?.id
      );
    },
    isMQTT() {
      // return isMQTT(this.equipment); // it seems to be wrongly cached by vue, while under anonymous mode
      return this?.equipment?.protocol?.is_mqtt_protocol || false;
    },
    hasEventManager() {
      // whether there is already action handler instance, do not allow its own
      return this.$route.path.startsWith("/dashboard/search");
    }
  },
  watch: {
    display: {
      handler(n) {
        if (n) {
          this.updateParent();
        }
      },
      deep: true
    }
  },
  methods: {
    updateParent() {
      let tpl = this.equipmentTemplate();
      if (tpl) {
        this.$root.$emit("dashboard:page_layout", {
          page_header: tpl?.page_header ? tpl?.page_header : null,
          page_side_bar: tpl?.page_side_bar ? tpl?.page_side_bar : null
        });
      }
    },
    allowDataMonitor() {
      return (
        this.$store.getters["dashboard/mode"] != "editor" &&
        this.refreshInterval &&
        !this.$route.path.startsWith("/dashboard/screen") &&
        !this.isMQTT
      );
    },
    dataMonitor(option) {
      if (option) {
        if (!this.allowDataMonitor()) {
          // turn off any monitoring eventually already triggered
          this.dataMonitor(false);
          return;
        }
        // regular data pooling
        if (!this._dataRefreshTimer) {
          // begin - test only
          // return; // manual refresh;
          // this.$root.config.dashboard.refreshInterval = 5000;
          // end
          let interval = this.refreshInterval;
          this._dataRefreshTimer = setInterval(
            () => {
              if (this._dataRefreshTimer) {
                this.refreshDashboard();
                // evaluate any interval changes
                if (interval != this.refreshInterval) {
                  this.dataMonitor(false);
                  this.$nextTick(() => {
                    this.dataMonitor(true); // force new config reload
                  });
                }
              }
            },
            interval,
            this
          );
        }
      } else {
        clearInterval(this._dataRefreshTimer);
        this._dataRefreshTimer = null;
      }
    },
    onMQTTStatus(status) {
      if (status == "CONNECTED") {
        this.dataMonitor(false);
        this.$rt.mqtt.subscribeOnlyOnTopics(
          Object.keys(this.mqttConnectorTopics || {}).map((t) => `${t}/#`)
        );
      }
    },
    async onResourcesReady() {
      if (this.mode == "editor" || this._settingUp || this.mqttObserver) return;
      this._settingUp = true;
      try {
        this.$nextTick(() => {
          this.mqttConnectorTopics = this.buildMQTTConnectorTopics();
          if (this.mqttConnectorTopics) {
            this.setupProtocol();
          } else {
            this.dataMonitor(true);
          }
          this._settingUp = false;
        });
      } catch (error) {
        this.dataMonitor(true);
        this._settingUp = false;
      }
    },
    setupProtocol() {
      if (this.mqttObserver || this.mode == "editor" || !this.$rt.mqtt) return;
      this.mqttObserver = true;
      this.onMQTTStatus(this.$rt.mqtt.status);
      return;
    },
    navigateEnd() {
      this.cid++; // force _cdim reload
      this.setupDataList();
    }
  },
  created() {
    this.enableMQTT(true);
    this._dataRefreshTimer = null;
    this.$root.editorZoom = 1;
  },
  beforeDestroy() {
    // Important:
    // it does not make global changes on destroy method, since this screen might be using at home page and already a new screen
    // has overwritten the store
    if (!this.equipmentId) return;
    this.enableMQTT(false);
    this.$store.dispatch("unselectEquipment");
    this.$root.$off("dashboard:enable_pooling", this.dataMonitor);
    this.dataMonitor(false);
  },
  mounted() {
    this.$root.$on("dashboard:enable_pooling", this.dataMonitor);
    if (this.equipment && this.templateReady) this.dataMonitor(true);
  }
};
</script>

<style scoped>
.overlay-loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
}
</style>
